module.exports = {
  title: `YOOBLOG`,
  description: `기록하고 공유합니다.`,
  author: `YOOVELOPER`,
  introduction: `It's not rocket science`,
  siteUrl: `https://yooveloper.dev`, // Your blog site url
  thumbnail: 'https://yoovelpoer.dev/thumbnail.png',
  social: {
    twitter: `yooveloper`, // Your Twitter account
    github: `yooveloper`, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
    linkedin: `yooveloper`, // Your LinkedIn account
    instagram: ``, // Your Instagram account
  },
  icon: `content/assets/felog.png`, // Add your favicon
  keywords: [`blog`, 'frontend', 'react'],
  comment: {
    disqusShortName: '', // Your disqus-short-name. check disqus.com.
    utterances: 'yooveloper/yooblog', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: '',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: '0', // Add your google analytics tranking ID
  ad: '', // Add your google adsense publisherId `ca-pub-xxxxxxxxxx`
}
